import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { systemReducer } from './system/reducers';

const rootReducer = combineReducers({
    system: systemReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middleware = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middleware);

    return createStore(
        rootReducer,
        composeWithDevTools(middlewareEnhancer)
    );
}
