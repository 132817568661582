import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setUsername} from '../../../store/system/actions';

interface AppProps {
    setUsername: (name: string) => void;
}

interface IState {
    newName: string
}

class Second extends Component<AppProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            newName: ''
        };
    }
    
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [event.target.name]: event.target.value
        } as any);
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.setUsername(this.state.newName);
    };

    render() {
        return (
            <div>
                <form noValidate onSubmit={this.handleSubmit}>
                    <input type="text" name="newName" placeholder="New Name" onChange={this.handleChange}/>
                    <button type="submit">Set</button>
                </form>
            </div>
        );
    }
}

export default connect(null, {setUsername})(Second);
