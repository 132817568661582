import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCounter, subtractFromCounter } from '../../../store/system/actions';

interface AppProps {
    addToCounter: (num: number) => void,
    subtractFromCounter: (num: number) => void
}

class Home extends Component<AppProps> {

    handleAdd = (num: number) => {
        this.props.addToCounter(num);
    };

    handleSubtract = (num: number) => {
        this.props.subtractFromCounter(num);
    };

    render() {
        return (
            <div>
                <button onClick={() => {this.handleSubtract(2)}}>-2</button>
                <button onClick={() => {this.handleSubtract(1)}}>-1</button>
                <button onClick={() => {this.handleAdd(1)}}>+1</button>
                <button onClick={() => {this.handleAdd(2)}}>+2</button>
            </div>
        );
    };
}

export default connect(null, { addToCounter, subtractFromCounter })(Home);
