import { MINUS_COUNTER, PLUS_COUNTER, SET_USERNAME, SystemActionTypes, SystemState } from './types';

const initialState: SystemState = {
    username: 'Unknown',
    counter: 42
};

export function systemReducer(
    state = initialState,
    action: SystemActionTypes
): SystemState {
    switch(action.type) {
        case PLUS_COUNTER:
            return {
                ...state,
                counter: state.counter + action.payload
            };
        case MINUS_COUNTER:
            return {
                ...state,
                counter: state.counter - action.payload
            };
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload
            };
        default:
            return state;
    }
}
