import { PLUS_COUNTER, MINUS_COUNTER, SET_USERNAME } from './types';

export function setUsername(name: string) {
    return {
        type: SET_USERNAME,
        payload: name
    };
}

export function addToCounter(num: number) {
    return {
        type: PLUS_COUNTER,
        payload: num
    }
}

export function subtractFromCounter(num: number) {
    return {
        type: MINUS_COUNTER,
        payload: num
    }
}
