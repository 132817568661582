export interface SystemState {
    username: string,
    counter: number
}

export const PLUS_COUNTER = 'PLUS_COUNTER';
export const MINUS_COUNTER = 'MINUS_COUNTER';
export const SET_USERNAME = 'SET_USERNAME';

interface PlusCounterAction {
    type: typeof PLUS_COUNTER,
    payload: number
}

interface MinusCounterAction {
    type: typeof MINUS_COUNTER,
    payload: number
}

interface SetUsernameAction {
    type: typeof SET_USERNAME,
    payload: string
}

export type SystemActionTypes = PlusCounterAction | MinusCounterAction | SetUsernameAction;
