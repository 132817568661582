import React, { Component } from 'react';
import './App.css';
import { SystemState } from './store/system/types';
import { AppState } from './store';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Home from './submodules/home/pages/home';
import Second from './submodules/home/pages/second';

interface AppProps {
    system: SystemState
}

class App extends Component<AppProps> {
    render() {
        const {counter, username} = this.props.system;
        return (
            <Router>
                <div className="App">
                    help, the counter is {counter}<br/>
                    Also, your name is {username}<br />
                    <Link to="/">Counter Page</Link><br/>
                    <Link to="/name">Name Page</Link><br/>
                    <Route path="/" exact component={Home}/>
                    <Route path="/name" component={Second}/>
                </div>
            </Router>

        );
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system
});

export default connect(mapStateToProps)(App);
